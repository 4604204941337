import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React, { useEffect, useRef, useState } from "react";

function ExportToPDF({ data }) {
  const canvasRef = useRef(null);

  // Funcția care desenează semnătura pe canvas
  const drawSignature = (signatureData) => {
    console.log(signatureData);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Setăm dimensiunile canvasului
    canvas.width = 800; // dimensiunea canvasului poate varia
    canvas.height = 400;

    ctx.clearRect(0, 0, canvas.width, canvas.height); // Curățăm canvas-ul
    ctx.lineWidth = 2.5;
    ctx.strokeStyle = signatureData[0].penColor || "black";
    ctx.lineJoin = "round";
    ctx.lineCap = "round";

    ctx.beginPath();
    signatureData.forEach((signatureLine) => {
      signatureLine.points.forEach((point, index) => {
        if (index === 0) {
          ctx.moveTo(point.x, point.y); // Mergem la primul punct
        } else {
          ctx.lineTo(point.x, point.y); // Trasăm linia la următorul punct
        }
      });
    });
    ctx.stroke();
  };

  const exportPdf = () => {
    const doc = new jsPDF("p", "pt", "a4");

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Proces verbal", doc.internal.pageSize.getWidth() / 2, 40, {
      align: "center",
    });

    doc.setFontSize(14);
    doc.text(
      "Premii Toneli\n24/08/2024",
      doc.internal.pageSize.getWidth() / 2,
      60,
      {
        align: "center",
      }
    );

    // Definim coloanele tabelului
    const tableColumn = [
      "Data si ora",
      "Nume Prenume",
      "Denumire Premiu",
      "Locatie",
      "Semnatura",
      "Sunt major si sunt de acord cu regulamentul",
    ];

    // Stocăm rândurile tabelului
    const tableRows = [];

    for (const row of data) {
      if (row.prize_won !== "loser") {
        console.log(row);
        // Desenăm semnătura pe canvas folosind datele din signature.points
        drawSignature(JSON.parse(row.signature));

        // Convertim canvas-ul într-o imagine base64
        const signatureImage = canvasRef.current.toDataURL("image/png");

        console.log(signatureImage);

        // Stocăm datele rândului
        tableRows.push([
          row.date,
          `${row.nume} ${row.prenume}`,
          row.prize_won === "evantai"
            ? "Evantai"
            : row.prize_won === "umbrela"
            ? "Umbrela de plaja"
            : "Cooler bag",
          row.location === "constanta"
            ? "Constanta"
            : row.location === "eforie"
            ? "Eforie"
            : "Neptun",
          signatureImage, // Folosim un obiect pentru a adăuga semnătura
          "DA",
        ]);
      }
    }

    // Adăugăm datele în PDF cu autoTable
    autoTable(doc, {
      head: [tableColumn],
      body: tableRows.map((row) => [
        row[0], // Data
        row[1], // Nume Prenume
        row[2], // Premiu
        row[3], // Locație
        "",
        // { image: row[4].image, fit: [50, 30] }, // Semnătura ca imagine (dimensiuni: 50x30)
        row[5], // Acceptare regulament
      ]),
      startY: 100, // Poziția de start a tabelului
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 5 }, // Stiluri generale
      columnStyles: {
        4: { cellWidth: 80 },
        5: { cellWidth: 70, overflow: "linebreak" }, // Setăm o lățime fixă pentru coloana cu semnătura
      },
      bodyStyles: {
        textColor: "#000000",
        lineColor: "#000000",
        lineWidth: 1,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#000000",
        lineColor: "#000000",
        lineWidth: 1,
        overflow: "linebreak",
      },
      didDrawCell: (data) => {
        if (
          data.row.section === "body" &&
          data.column.index === 4 &&
          tableRows[data.row.index][4]
        ) {
          const signatureImage = tableRows[data.row.index][4];

          // Calculăm dimensiunile și poziția imaginii
          const cellWidth = data.cell.width;
          const cellHeight = data.cell.height;
          const imageWidth = 65;
          const imageHeight = 40;

          // Poziționăm imaginea la 5 unități de la marginea stângă a celulei
          const xOffset = 5; // 5 unități de la marginea stângă
          const yOffset = (cellHeight - imageHeight) / 2; // Offset pentru a centra imaginea pe axa Y

          doc.addImage(
            signatureImage,
            "PNG",
            data.cell.x + xOffset,
            data.cell.y + yOffset,
            imageWidth,
            imageHeight
          );
        }
      },
    });

    // Salvăm PDF-ul
    doc.save("export.pdf");
  };

  return (
    <div>
      <canvas ref={canvasRef} style={{ display: "none" }} />{" "}
      {/* Canvas-ul este invizibil */}
      <button onClick={exportPdf} className="export-pdf-button">
        Export PDF
      </button>
    </div>
  );
}

export default ExportToPDF;
