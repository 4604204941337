import React from "react";

function NotFound() {
  return (
    <div className="not-found-page">
      <h1>404</h1>
      <p>Pagina accesată nu există</p>
    </div>
  );
}

export default NotFound;
