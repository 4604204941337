import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./backend.css";

import { ReactComponent as LogoToneli } from "../logo_toneli.svg";

import { FaBars } from "react-icons/fa6";
import { FaX } from "react-icons/fa6";
import { FaRightFromBracket } from "react-icons/fa6";

function AdminLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  console.log("Loca", location);

  const [userData, setUserData] = useState(null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const token = localStorage.getItem("jwt_token");

  const getUserData = async () => {
    const response = await fetch(`/api/v1/backend/check-token/?token=${token}`);

    if (response.ok) {
      const body = await response.json();

      if (body.status === "success") {
        setUserData({
          username: body.username,
          name: body.name,
          id: body.id,
        });
      } else {
        localStorage.removeItem("jwt_token");
        navigate("/admin");
      }
    }
  };

  const handleToggleMenu = (e) => {
    e.preventDefault();
    setMenuIsOpen((prev) => !prev);
  };
  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("jwt_token");
    navigate("/admin");
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  if (!token) {
    return <Navigate to="/admin" />;
  } else {
    if (userData === null) {
      getUserData();
    }
  }

  return (
    <div>
      <div className="dashboard-header">
        <div className="dashboard-header-container">
          <div className="dashboard-header-left">
            <div className="dashboard-header-menu-button">
              <button onClick={handleToggleMenu}>
                {menuIsOpen ? <FaX /> : <FaBars />}
              </button>
            </div>
            <div className="dashboard-header-logo">
              <LogoToneli />
            </div>
          </div>

          <div className="dashboard-header-right">
            <span className="dashboard-header-user-name">{userData?.name}</span>
            <button
              className="dashboard-header-logout-button"
              onClick={handleLogOut}
            >
              <FaRightFromBracket />
            </button>
          </div>
        </div>
      </div>
      <div className="dashboard-layout">
        <div className={`dashboard-menu ${menuIsOpen ? "active" : ""}`}>
          <nav className="dashboard-menu-nav">
            <h3>Meniu</h3>
            <ul className="dashboard-menu-nav-ul">
              <li className="dashboard-menu-nav-li">
                <a
                  href="/admin/dashboard"
                  className={`dashboard-menu-nav-a  ${
                    location.pathname === "/admin/dashboard" ? "active" : ""
                  }`}
                >
                  Dashboard
                </a>
              </li>
              <li className="dashboard-menu-nav-li">
                <a
                  href="/admin/dashboard/submissions"
                  className={`dashboard-menu-nav-a ${
                    location.pathname === "/admin/dashboard/submissions"
                      ? "active"
                      : ""
                  }`}
                >
                  Înscrieri
                </a>
              </li>
              <li className="dashboard-menu-nav-li">
                <a
                  href="/admin/dashboard/prizes"
                  className={`dashboard-menu-nav-a ${
                    location.pathname === "/admin/dashboard/prizes"
                      ? "active"
                      : ""
                  }`}
                >
                  Premii
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="dashboard-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
