import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "signature_pad";

function ProcesVerbalForm({ winnerId, changeToEndPage }) {
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);

  const [signatureData, setSignatureData] = useState(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = window.innerWidth < 1300 ? window.innerWidth * 0.9 : 1100; // 90% din lățimea ecranului
      canvas.height = 400; // Înălțime fixă de 500px

      signaturePadRef.current = new SignaturePad(canvasRef.current);
    }
  }, []);

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const saveSignature = () => {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const newSignatureData = signaturePadRef.current.toData();
      setSignatureData(newSignatureData);

      sendSignatureDataToBackend(newSignatureData);
    } else {
      alert("Semnătura este goală!");
    }
  };

  const sendSignatureDataToBackend = async (data) => {
    const formData = new FormData();

    formData.append("winner_id", winnerId);
    formData.append("signature", JSON.stringify(data));

    const response = await fetch("/api/v1/campaign/update/signature", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const body = await response.json();

      if (body.status === "success") {
        changeToEndPage();
      }
    }
  };

  useEffect(() => {
    if (signatureData !== null) {
      console.log("signature data ", signatureData);
    }
  }, [signatureData]);

  return (
    <>
      <div className="signature-container">
        <h2>Vă rugăm să semnați în chenarul alb</h2>
        <canvas ref={canvasRef} className="signature-canvas"></canvas>
        <div className="signature-buttons-container">
          <button className="signature-clear-button" onClick={clearSignature}>
            Șterge
          </button>
          <button className="signature-save-button" onClick={saveSignature}>
            Salvează
          </button>
        </div>
      </div>
    </>
  );
}

export default ProcesVerbalForm;
