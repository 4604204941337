import React, { useEffect, useState, useRef } from "react";
import SignaturePad from "signature_pad";

import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExportToPDF from "./ExportToPDF";

function SubmissionsPage() {
  const [submissionsData, setSubmissionsData] = useState([]);
  const [filtredData, setFiltredData] = useState([]);

  const [signPv, setSignPv] = useState(null);

  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [prizeFilter, setPrizeFilter] = useState("");

  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);

  const [signatureData, setSignatureData] = useState(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = window.innerWidth < 1300 ? window.innerWidth * 0.9 : 1100; // 90% din lățimea ecranului
      canvas.height = 300; // Înălțime fixă de 500px

      signaturePadRef.current = new SignaturePad(canvasRef.current);
    }
  }, [signPv]);

  const saveSignature = () => {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const newSignatureData = signaturePadRef.current.toData();
      setSignatureData(newSignatureData);

      sendSignatureDataToBackend(newSignatureData);
    } else {
      alert("Semnătura este goală!");
    }
  };

  const sendSignatureDataToBackend = async (data) => {
    const formData = new FormData();

    formData.append("winner_id", signPv);
    formData.append("signature", JSON.stringify(data));

    const response = await fetch("/api/v1/campaign/update/signature", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const body = await response.json();

      if (body.status === "success") {
        getSubmissions();
        setSignPv(null);
        toast.success("Semnătura au fost actualizată cu success", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      } else {
        toast.error("EROARE - A apărut o problemă", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    } else {
      toast.error("EROARE - A apărut o problemă", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const getSubmissions = async () => {
    const response = await fetch("/api/v1/campaign/submissions");
    if (response.ok) {
      const body = await response.json();
      console.log(body);
      setSubmissionsData([...body]);
      setFiltredData([...body]);
    }
  };

  const filterTableData = () => {
    const filtered = submissionsData.filter((submission) => {
      return (
        ((!nameFilter ||
          `${submission.nume} ${submission.prenume}`
            .toLowerCase()
            .includes(nameFilter.toLowerCase())) &&
          (!emailFilter ||
            submission.email
              .toLowerCase()
              .includes(emailFilter.toLowerCase())) &&
          (!phoneFilter || submission.telefon.includes(phoneFilter)) &&
          (!locationFilter ||
            submission.location
              .toLowerCase()
              .includes(locationFilter.toLowerCase())) &&
          prizeFilter === "") ||
        submission?.prize_won?.toLowerCase() === prizeFilter.toLowerCase()
      );
    });

    setFiltredData(filtered);
  };

  useEffect(() => {
    console.log("Raw", submissionsData);
    console.log("Filtred", filtredData);
    filterTableData();
  }, [submissionsData]);

  useEffect(() => {
    getSubmissions();
  }, []);

  useEffect(() => {
    filterTableData();
  }, [nameFilter, emailFilter, phoneFilter, locationFilter, prizeFilter]);

  const givePrize = async (id) => {
    const response = await fetch(`/api/v1/campaign/give-prize/?id=${id}`);
    if (response.ok) {
      const body = await response.json();

      if (body.status === "success") {
        getSubmissions();
        toast.success("Premiul a fost acordat", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      } else {
        toast.error("EROARE - A apărut o problemă", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    } else {
      toast.error("EROARE - A apărut o problemă", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  const openSignPv = (id) => {
    setSignPv(id);
    setSignatureData(null);
  };

  const closeSignPv = (e) => {
    e.preventDefault();
    setSignPv(null);
    setSignatureData(null);
  };

  return (
    <div className="admin-page-content">
      <h1>Înscrieri</h1>
      <ExportToPDF data={submissionsData} />
      <div>
        <div className="admin-page-filters">
          <div className="admin-page-filter-container">
            <label>Nume</label>
            <input
              name="nume"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </div>
          <div className="admin-page-filter-container">
            <label>Email</label>
            <input
              name="email"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
            />
          </div>
          <div className="admin-page-filter-container">
            <label>Telefon</label>
            <input
              name="telefon"
              value={phoneFilter}
              onChange={(e) => setPhoneFilter(e.target.value)}
            />
          </div>
          <div className="admin-page-filter-container">
            <label>Locatie</label>
            <select
              name="location"
              value={locationFilter}
              onChange={(e) => setLocationFilter(e.target.value)}
            >
              <option value="">Toate</option>
              <option value="constanta">Constanța</option>
              <option value="eforie">Eforie</option>
              <option value="neptun">Neptun</option>
            </select>
          </div>
          <div className="admin-page-filter-container">
            <label>Premiu</label>
            <select
              name="prize"
              value={prizeFilter}
              onChange={(e) => setPrizeFilter(e.target.value)}
            >
              <option value="">Toate</option>
              <option value="umbrela">Umbrelă de plajă</option>
              <option value="evantai">Evantai</option>
              <option value="cooler-bag">Cooler bag</option>
            </select>
          </div>
        </div>
      </div>

      <div className="table-container">
        <table className="submissions-table">
          <thead>
            <tr>
              <th className="column-id">ID</th>
              <th className="column-date">Data</th>
              <th className="column-name">Nume</th>
              <th className="column-email">Email</th>
              <th className="column-telefon">Telefon</th>
              <th className="column-judet">Județ</th>
              <th className="column-oras">Oraș</th>
              <th className="column-location">Locația</th>
              <th className="column-prize">Premiu</th>
              <th className="column-signature">Semnătură</th>
              <th className="column-recived">A primit premiul</th>
              <th className="column-actions">Acțiuni</th>
              <th className="column-id">ID</th>
            </tr>
          </thead>
          <tbody>
            {filtredData.map((submission) => (
              <tr key={submission?.id}>
                <td className="column-id">{submission?.id}</td>
                <td className="column-date">{submission?.date}</td>
                <td className="column-name">
                  {submission?.nume} {submission?.prenume}
                </td>
                <td className="column-email">
                  <a href={`mailto: ${submission?.email}`}>
                    {submission?.email}
                  </a>
                </td>
                <td className="column-telefon">
                  <a href={`tel: ${submission?.telefon}`}>
                    {submission?.telefon}
                  </a>
                </td>
                <td className="column-judet">{submission?.judet}</td>
                <td className="column-oras">{submission?.oras}</td>
                <td className="column-location">
                  {submission?.location === "constanta" ? (
                    "Constanța"
                  ) : (
                    <>
                      {submission?.location === "eforie" ? "Eforie" : "Neptun"}
                    </>
                  )}
                </td>
                <td className="column-prize">
                  {submission?.prize_won === "umbrela" ? (
                    "Umbrelă de plajă"
                  ) : (
                    <>
                      {submission?.prize_won === "evantai" ? (
                        "Evantai"
                      ) : (
                        <>
                          {submission?.prize_won === "cooler-bag"
                            ? "Cooler bag"
                            : "-"}
                        </>
                      )}
                    </>
                  )}
                </td>
                <td className="column-signature">
                  {submission?.signature !== null ? (
                    <span style={{ color: "green", fontWeight: "900" }}>
                      DA
                    </span>
                  ) : (
                    <span style={{ color: "red", fontWeight: "900" }}>NU</span>
                  )}
                </td>
                <td className="column-recived">
                  {submission?.prize_recieved === "0" ? (
                    <span style={{ color: "red", fontWeight: "900" }}>NU</span>
                  ) : (
                    <span style={{ color: "green", fontWeight: "900" }}>
                      DA
                    </span>
                  )}
                </td>

                <td className="column-actions">
                  <div className="column-actions-container">
                    {submission?.prize_recieved === "0" &&
                    (submission?.prize_won === "umbrela" ||
                      submission?.prize_won === "evantai" ||
                      submission?.prize_won === "cooler-bag") ? (
                      <>
                        <button
                          className="give-prize-button"
                          onClick={(e) => {
                            e.preventDefault();
                            givePrize(submission?.id);
                          }}
                        >
                          Oferă premiul
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    {submission?.prize_won !== "loser" ? (
                      <>
                        {submission?.signature !== null ? (
                          ""
                        ) : (
                          <>
                            <button
                              className="give-open-sign-pv-button"
                              onClick={(e) => {
                                e.preventDefault();
                                openSignPv(submission?.id);
                              }}
                            >
                              Semnează PV
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </td>
                <td className="column-id">{submission?.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {signPv !== null ? (
        <>
          <div className="sign-pv-container">
            <div className="sign-pv-box">
              <h2 style={{ textAlign: "center", color: "#ffffff" }}>
                Vă rugăm să semnați în chenarul alb
              </h2>
              <canvas ref={canvasRef} className="signature-canvas"></canvas>
              <div className="signature-buttons-container">
                <button
                  className="signature-clear-button"
                  onClick={clearSignature}
                >
                  Șterge
                </button>
                <button
                  className="signature-save-button"
                  onClick={saveSignature}
                >
                  Salvează
                </button>
                <button
                  className="signature-clear-button"
                  onClick={closeSignPv}
                >
                  Anulează
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </div>
  );
}

export default SubmissionsPage;
