import React, { useEffect, useState } from "react";

function DashboardPage() {
  const [pageData, setPageData] = useState({
    submissions: {
      constanta: 0,
      eforie: 0,
      neptun: 0,
    },
    prizes: {
      constanta: {
        umbrela: { quantity: 0, probability: 0 },
        evantai: { quantity: 0, probability: 0 },
        coolerBag: { quantity: 0, probability: 0 },
      },
      eforie: {
        umbrela: { quantity: 0, probability: 0 },
        evantai: { quantity: 0, probability: 0 },
        coolerBag: { quantity: 0, probability: 0 },
      },
      neptun: {
        umbrela: { quantity: 0, probability: 0 },
        evantai: { quantity: 0, probability: 0 },
        coolerBag: { quantity: 0, probability: 0 },
      },
    },
  });

  const getDashboardData = async () => {
    const response = await fetch("/api/v1/campaign/all-data");
    if (response.ok) {
      const body = await response.json();

      console.log(body);

      setPageData({
        submissions: {
          constanta: body?.submissions.constanta,
          eforie: body?.submissions.eforie,
          neptun: body?.submissions.neptun,
        },
        prizes: {
          constanta: {
            umbrela: {
              quantity: parseInt(body?.prizes.constanta.umbrela.quantity),
              probability: body?.prizes.constanta.umbrela.probability,
            },
            evantai: {
              quantity: parseInt(body?.prizes.constanta.evantai.quantity),
              probability: body?.prizes.constanta.evantai.probability,
            },
            coolerBag: {
              quantity: parseInt(body?.prizes.constanta.coolerBag.quantity),
              probability: body?.prizes.constanta.coolerBag.probability,
            },
          },
          eforie: {
            umbrela: {
              quantity: parseInt(body?.prizes.eforie.umbrela.quantity),
              probability: body?.prizes.eforie.umbrela.probability,
            },
            evantai: {
              quantity: parseInt(body?.prizes.eforie.evantai.quantity),
              probability: body?.prizes.eforie.evantai.probability,
            },
            coolerBag: {
              quantity: parseInt(body?.prizes.eforie.coolerBag.quantity),
              probability: body?.prizes.eforie.coolerBag.probability,
            },
          },
          neptun: {
            umbrela: {
              quantity: parseInt(body?.prizes.neptun.umbrela.quantity),
              probability: body?.prizes.neptun.umbrela.probability,
            },
            evantai: {
              quantity: parseInt(body?.prizes.neptun.evantai.quantity),
              probability: body?.prizes.neptun.evantai.probability,
            },
            coolerBag: {
              quantity: parseInt(body?.prizes.neptun.coolerBag.quantity),
              probability: body?.prizes.neptun.coolerBag.probability,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    // Funcția care face fetch-ul datelor
    const fetchData = () => {
      getDashboardData();
    };

    // Apelează funcția imediat
    fetchData();

    // Setează intervalul pentru fetch la fiecare 60 de secunde (60000 milisecunde)
    const intervalId = setInterval(fetchData, 60000);

    // Cleanup: oprește intervalul când componenta este demontată
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="admin-page-content">
      <h1>Dashboard</h1>
      <div>
        <h2>
          Inscrieri -{" "}
          {pageData.submissions.constanta +
            pageData.submissions.eforie +
            pageData.submissions.neptun}
        </h2>
        <div>
          <p>Constanța - {pageData.submissions.constanta}</p>
          <p>Eforie - {pageData.submissions.eforie}</p>
          <p>Neptun - {pageData.submissions.neptun}</p>
        </div>
      </div>
      <div>
        <h2>
          Premii -{" "}
          {pageData.prizes.constanta.umbrela.quantity +
            pageData.prizes.constanta.evantai.quantity +
            pageData.prizes.constanta.coolerBag.quantity +
            pageData.prizes.eforie.umbrela.quantity +
            pageData.prizes.eforie.evantai.quantity +
            pageData.prizes.eforie.coolerBag.quantity +
            pageData.prizes.neptun.umbrela.quantity +
            pageData.prizes.neptun.evantai.quantity +
            pageData.prizes.neptun.coolerBag.quantity}{" "}
          rămase
        </h2>
        <div>
          <div>
            <h3>
              Constanța -{" "}
              {pageData.prizes.constanta.umbrela.quantity +
                pageData.prizes.constanta.evantai.quantity +
                pageData.prizes.constanta.coolerBag.quantity}{" "}
              / 342
            </h3>
            <div>
              <p>
                Umbrelă (probabilitate{" "}
                {pageData.prizes.constanta.umbrela.probability * 100}%) -{" "}
                {pageData.prizes.constanta.umbrela.quantity} / 50
              </p>
              <p>
                Cooler bag (probabilitate{" "}
                {pageData.prizes.constanta.coolerBag.probability * 100}%) -{" "}
                {pageData.prizes.constanta.coolerBag.quantity} / 96
              </p>
              <p>
                Evantai (probabilitate{" "}
                {pageData.prizes.constanta.evantai.probability * 100}%) -{" "}
                {pageData.prizes.constanta.evantai.quantity} / 196
              </p>
            </div>
          </div>
          <div>
            <h3>
              Eforie -{" "}
              {pageData.prizes.eforie.umbrela.quantity +
                pageData.prizes.eforie.evantai.quantity +
                pageData.prizes.eforie.coolerBag.quantity}{" "}
              / 344
            </h3>
            <div>
              <p>
                Umbrelă (probabilitate{" "}
                {pageData.prizes.eforie.umbrela.probability * 100}%) -{" "}
                {pageData.prizes.eforie.umbrela.quantity} / 50
              </p>
              <p>
                Cooler bag (probabilitate{" "}
                {pageData.prizes.eforie.coolerBag.probability * 100}%) -{" "}
                {pageData.prizes.eforie.coolerBag.quantity} / 97
              </p>
              <p>
                Evantai (probabilitate{" "}
                {pageData.prizes.eforie.evantai.probability * 100}%) -{" "}
                {pageData.prizes.eforie.evantai.quantity} / 197
              </p>
            </div>
          </div>
          <div>
            <h3>
              Neptun -{" "}
              {pageData.prizes.neptun.umbrela.quantity +
                pageData.prizes.neptun.evantai.quantity +
                pageData.prizes.neptun.coolerBag.quantity}{" "}
              / 344
            </h3>
            <div>
              <p>
                Umbrelă (probabilitate{" "}
                {pageData.prizes.neptun.umbrela.probability * 100}%) -{" "}
                {pageData.prizes.neptun.umbrela.quantity} / 50
              </p>
              <p>
                Cooler bag (probabilitate{" "}
                {pageData.prizes.neptun.coolerBag.probability * 100}%) -{" "}
                {pageData.prizes.neptun.coolerBag.quantity} / 97
              </p>
              <p>
                Evantai (probabilitate{" "}
                {pageData.prizes.neptun.evantai.probability * 100}%) -{" "}
                {pageData.prizes.neptun.evantai.quantity} / 197
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
