import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const handleLogOut = (e) => {
    e.preventDefault();
    console.log("LOGOUT");

    localStorage.removeItem("register_token");
    localStorage.removeItem("register_location");

    navigate("/login");
  };

  return (
    <footer>
      <button onClick={handleLogOut} className="location-disconect-button">
        Deconectare
      </button>
    </footer>
  );
}

export default Footer;
