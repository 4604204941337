import React, { useEffect, useState } from "react";
import ScratCardGame from "./ScratCardGame";

import RegisterForm from "./RegisterForm";
import ProcesVerbalForm from "./ProcesVerbalForm";

import umbrelaImg from "./simulare_umbrela.png";
import evantaiImg from "./simulare_evantai.png";
import coolerboxImg from "./simulare_punga.png";
import bigPrizeImg from "./premii_mari_Toneli.png";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";

function CampaignFormPage({ location }) {
  const navigate = useNavigate();
  const locationHook = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("register_token");
    const locationReg = localStorage.getItem("register_location");

    console.log("token - ", token);
    if (token === null) {
      navigate(`/login`);
    } else {
      if (locationHook.pathname !== `/activare/${locationReg}`) {
        navigate(`/activare/${locationReg}`);
      }
    }
  }, []);

  const [page, setPage] = useState(0);

  const [prizeWon, setPrizeWon] = useState(null);
  const [winnerId, setWinnerId] = useState(null);

  const changeToFirstPage = (e) => {
    e.preventDefault();
    setPage(1);
  };

  const changeToSecondPage = (id, prize) => {
    setPrizeWon(prize);
    setWinnerId(id);
    setPage(2);
  };

  const changeToThirdPage = () => {
    setPage(3);
  };

  const changeToEndPage = () => {
    setPage(4);
  };

  const handleResetForm = () => {
    setPage(0);
    setPrizeWon(null);
    setWinnerId(null);
  };

  return (
    <>
      <div>
        {page === 0 ? (
          <>
            <div className="start-register-page">
              <div className="start-register-page-headliner">
                <h1>
                  Înscrie-te la campania Toneli și poți câștiga unul dintre cele
                  3 premii instant
                </h1>
                <h2>și intri automat la extragerea pentru marele premiu</h2>
              </div>
              <div className="start-register-prizes-container">
                <div className="start-register-prize-item">
                  <img
                    className="start-register-prize-item-img"
                    src={umbrelaImg}
                  />
                  <h3 className="start-register-prize-item-title">
                    50x Umbrelă de plajă
                  </h3>
                </div>
                <div className="start-register-prize-item">
                  <img
                    className="start-register-prize-item-img"
                    src={evantaiImg}
                  />
                  <h3 className="start-register-prize-item-title">
                    200x Evantai
                  </h3>
                </div>
                <div className="start-register-prize-item">
                  <img
                    className="start-register-prize-item-img"
                    src={coolerboxImg}
                  />
                  <h3 className="start-register-prize-item-title">
                    100x Cooler bag
                  </h3>
                </div>
              </div>

              <div className="start-register-big-prize-item-img-container">
                <img
                  className="start-register-big-prize-item-img"
                  src={bigPrizeImg}
                />
                <h2>1x Frigider plin cu ouă Toneli și set de tigăi</h2>
              </div>

              <h2>Pentru a începe înscrierea, apăsați pe butonul de mai jos</h2>
              <button
                className="start-register-button"
                onClick={changeToFirstPage}
              >
                Începe
              </button>
            </div>
          </>
        ) : (
          <>
            {page === 1 ? (
              <>
                <RegisterForm
                  location={location}
                  changeToSecondPage={changeToSecondPage}
                />
              </>
            ) : (
              <>
                {page === 2 ? (
                  <>
                    <ScratCardGame
                      changeToThirdPage={changeToThirdPage}
                      umbrelaImg={umbrelaImg}
                      coolerboxImg={coolerboxImg}
                      evantaiImg={evantaiImg}
                      prize={prizeWon}
                      newRegister={handleResetForm}
                    />
                  </>
                ) : (
                  <>
                    {page === 3 ? (
                      <>
                        <ProcesVerbalForm
                          winnerId={winnerId}
                          changeToEndPage={changeToEndPage}
                        />
                      </>
                    ) : (
                      <>
                        <div className="end-page">
                          <h2>Înscrierea a fost realizată cu succes!</h2>

                          <button
                            className="end-page-reset-button"
                            onClick={handleResetForm}
                          >
                            Înscriere nouă
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default CampaignFormPage;
