import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { ReactComponent as LogoToneli } from "./logo_toneli.svg";
import Footer from "./Footer";

import bgPath from "./bg_toneli.png";
import LocationLogin from "./LocationLogin";

function Layout() {
  return (
    <main className="page-main">
      <div className="content-box">
        <div className="page-background-container">
          <img src={bgPath} className="page-background" />
        </div>
        <div className="logo-container">
          <LogoToneli />
        </div>
        <div className="page-content">
          <Outlet />
        </div>
        {/* <Footer /> */}
      </div>
    </main>
  );
}

export default Layout;
