import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NotFound from "./NotFound";
import Layout from "./Layout";
import CampaignFormPage from "./CampaignFormPage";
import AdminLayout from "./backend/AdminLayout";
import AdminLoginLayout from "./backend/AdminLoginLayout";
import Login from "./backend/Login";
import SubmissionsPage from "./backend/SubmissionsPage";
import PrizesPage from "./backend/PrizesPage";
import DashboardPage from "./backend/DashboardPage";
import LocationLogin from "./LocationLogin";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<LocationLogin />} />
          <Route index element={<CampaignFormPage location={"all"} />} />
          <Route
            path="activare/constanta"
            element={<CampaignFormPage location={"constanta"} />}
          />
          <Route
            path="activare/eforie"
            element={<CampaignFormPage location={"eforie"} />}
          />
          <Route
            path="activare/neptun"
            element={<CampaignFormPage location={"neptun"} />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="admin" element={<AdminLoginLayout />}>
          <Route index element={<Login />} />
        </Route>
        <Route path="admin/dashboard" element={<AdminLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path="prizes" element={<PrizesPage />} />
          <Route path="submissions" element={<SubmissionsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
