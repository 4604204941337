import React, { useEffect, useRef, useState } from "react";
import { FaCaretDown } from "react-icons/fa6";

function SelectInput({ value, data, name, onChange, isReadOnly }) {
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [filtredData, setFiltredData] = useState([...data]);

  const [inputValue, setInputValue] = useState(value);

  const selectInputRef = useRef(null);

  const handleSelectorInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);

    const normalizedValue = value
      .normalize("NFD") // Descompune diacriticele
      .replace(/[\u0300-\u036f]/g, "");

    setFiltredData(
      data.filter((element) => {
        // Normalizează fiecare element din array pentru a elimina diacriticele
        const normalizedElement = element
          .normalize("NFD") // Descompune diacriticele
          .replace(/[\u0300-\u036f]/g, ""); // Elimină diacriticele

        // Returnează elementele care conțin valoarea căutată, indiferent de diacritice
        return normalizedElement
          .toLowerCase()
          .includes(normalizedValue.toLowerCase());
      })
    );
  };

  const handleOpenSelector = (e) => {
    setSelectorOpen(true);
  };
  const handleCloseSelector = (e) => {
    setSelectorOpen(false);
  };

  const setSelectInputValue = (e) => {
    e.preventDefault();
    onChange(e.target.value);
    setInputValue(e.target.value);
    setSelectorOpen(false);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);
  useEffect(() => {
    setFiltredData(data);
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectInputRef.current &&
        !selectInputRef.current.contains(event.target)
      ) {
        setSelectorOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup listener-ul când componenta se demontează
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectInputRef]);
  return (
    <div className="select-input" ref={selectInputRef}>
      <input
        type="text"
        value={inputValue}
        name={name}
        onChange={handleSelectorInputChange}
        onFocus={handleOpenSelector}
        // onBlur={handleCloseSelector}
        onClick={handleOpenSelector}
      />
      <FaCaretDown className="select-input-icon" />

      <div
        className={` ${
          selectorOpen ? "active" : ""
        } select-input-options-container`}
      >
        {filtredData.map((element, index) => (
          <button
            key={index}
            className="select-input-option"
            value={element}
            onClick={setSelectInputValue}
          >
            {element}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SelectInput;
