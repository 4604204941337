import React, { useEffect, useState } from "react";

import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PrizesPage() {
  const [selectedLocation, setSelectedLocation] = useState("constanta");
  const [prizesData, setPrizesData] = useState([]);

  const getPrizesData = async () => {
    const response = await fetch("/api/v1/campaign/prizes");

    if (response.ok) {
      const body = await response.json();

      console.log(body);

      setPrizesData(body);
    }
  };

  const handleChangeProbability = (e) => {
    const { name, value } = e.target;
    const oldPrizesData = [...prizesData];
    const namePrize = name.split(".");
    const location = namePrize[0];
    const prize = namePrize[1];
    console.log(location, prize);

    oldPrizesData.forEach((element) => {
      if (element.name === prize && element.location === location) {
        element.probability = value;
      }
    });
    setPrizesData(oldPrizesData);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("location", selectedLocation);

    prizesData.forEach((element) => {
      if (element.location === selectedLocation) {
        formData.append(element.name, element.probability);
      }
    });

    const response = await fetch("/api/v1/campaign/update-prizes", {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      const body = await response.json();
      console.log(body);
      if (body.status === "success") {
        getPrizesData();
        toast.success("Premiile au fost actualizate cu success", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      } else {
        toast.error("EROARE - A apărut o problemă", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    } else {
      toast.error("EROARE - A apărut o problemă", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    }
  };

  useEffect(() => {
    // Funcția care face fetch-ul datelor
    const fetchData = () => {
      getPrizesData();
    };

    // Apelează funcția imediat
    fetchData();
  }, []);

  return (
    <div className="admin-page-content">
      <h1>Premii</h1>

      <form onSubmit={handleFormSubmit}>
        <div className="admin-page-filter-container">
          <label>Locație</label>
          <select
            name="location"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option value="constanta">Constanța</option>
            <option value="eforie">Eforie</option>
            <option value="neptun">Neptun</option>
          </select>
        </div>
        <div>
          <table className="prizes-details-table">
            <thead>
              <tr>
                <th>Premiu</th>
                <th>Cantitate</th>
                <th className="table-last-col">Probabilitate</th>
              </tr>
            </thead>
            <tbody>
              {prizesData
                .filter((prize) => prize.location === selectedLocation)
                .map((prize) => (
                  <tr key={prize.id}>
                    <td>
                      {prize.name === "umbrela"
                        ? "Umbrelă de plajă"
                        : prize.name === "evantai"
                        ? "Evantai"
                        : prize.name === "cooler-bag"
                        ? "Cooler bag"
                        : "Necâștigator"}
                    </td>
                    <td>
                      {prize.name === "loser" ? "∞" : prize.actual_quantity}
                    </td>
                    <td className="table-last-col">
                      <input
                        type="number"
                        step={0.01}
                        min={0}
                        max={1}
                        name={`${selectedLocation}.${prize.name}`}
                        value={prize.probability}
                        onChange={handleChangeProbability}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <button className="prizes-save-button">Salvează</button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default PrizesPage;
