import React, { useEffect, useState } from "react";
import SelectInput from "./SelectInput";

import CountyAndCitiesOfRomania from "./judete.json";
import { mx_fractal_noise_vec2 } from "three/examples/jsm/nodes/Nodes.js";

function RegisterForm({ location, changeToSecondPage }) {
  const [formError, setFormError] = useState(null);
  const [loading, setIsLoading] = useState(false);

  const [formInputs, setFormInputs] = useState({
    nume: "",
    prenume: "",
    email: "",
    telefon: "",
    judet: "",
    oras: "",
    tc: false,
    gdpr: false,
    location: location,
  });

  const judete = CountyAndCitiesOfRomania.judete.map(
    (countyData) => countyData.nume
  );

  const orase = {};

  CountyAndCitiesOfRomania.judete.forEach((countyData) => {
    orase[countyData.nume] = countyData.localitati.map((cityData) =>
      cityData?.comuna
        ? `${cityData?.nume} (Comuna ${cityData?.comuna})`
        : `${cityData?.nume}`
    );
  });

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;

    const realName = name.replace("toneli_", "");

    if (realName === "telefon") {
      let filteredValue = value.replace(/[^0-9+]/g, "");

      if (filteredValue.startsWith("+")) {
        filteredValue = `+${filteredValue.replace(/[^0-9]/g, "")}`;
      } else {
        filteredValue = filteredValue.replace(/[^0-9]/g, "");
      }

      setFormInputs((prev) => ({
        ...prev,
        [realName]: filteredValue,
      }));
    } else {
      setFormInputs((prev) => ({
        ...prev,
        [realName]: value,
      }));
    }
  };

  const handleFormCheckboxChange = (e) => {
    const { name, checked } = e.target;

    const realName = name.replace("toneli_", "");

    setFormInputs((prev) => ({
      ...prev,
      [realName]: checked,
    }));
  };

  useEffect(() => {
    console.log(formInputs);
  }, [formInputs]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formInputs.nume === "") {
      setFormError("nume");
      return;
    }
    if (formInputs.prenume === "") {
      setFormError("prenume");
      return;
    }
    if (formInputs.email === "") {
      setFormError("email");
      return;
    }
    if (formInputs.telefon === "") {
      setFormError("telefon");
      return;
    }
    if (formInputs.judet === "") {
      setFormError("judet");
      return;
    }
    if (formInputs.oras === "") {
      setFormError("oras");
      return;
    }
    if (formInputs.tc === "") {
      setFormError("tc");
      return;
    }
    if (formInputs.gdpr === "") {
      setFormError("gdpr");
      return;
    }

    const formData = new FormData();

    formData.append("nume", formInputs.nume);
    formData.append("prenume", formInputs.prenume);
    formData.append("email", formInputs.email);
    formData.append("telefon", formInputs.telefon);
    formData.append("judet", formInputs.judet);
    formData.append("oras", formInputs.oras);
    formData.append("tc", formInputs.tc);
    formData.append("gdpr", formInputs.gdpr);
    formData.append("location", formInputs.location);

    const response = await fetch("/api/v1/campaign/submit", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const body = await response.json();

      console.log("Response - ", body);

      if (body?.status === "success") {
        changeToSecondPage(body.insert_id, body.prize_won);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (body?.error === "no-prizes") {
          setFormError("no-prizes");
          return;
        }
      }
    }
  };

  const handleCountyInputChange = (value) => {
    setFormInputs((prev) => ({
      ...prev,
      judet: value,
      oras: "",
    }));
  };

  const handleCityInputChange = (value) => {
    setFormInputs((prev) => ({
      ...prev,
      oras: value,
    }));
  };

  const closeErrorMessage = (e) => {
    e.preventDefault();

    setFormError(null);
  };

  return (
    <>
      <form className="form" onSubmit={handleFormSubmit} autoComplete="off">
        <div className="form-container">
          <div className="form-input-container">
            <label>Nume</label>
            <input
              type="text"
              name="toneli_nume"
              value={formInputs.nume}
              onChange={handleFormInputChange}
              autoComplete="off"
              required
            />
          </div>
          <div className="form-input-container">
            <label>Prenume</label>
            <input
              type="text"
              name="toneli_prenume"
              value={formInputs.prenume}
              onChange={handleFormInputChange}
              autoComplete="off"
              required
            />
          </div>
          <div className="form-input-container">
            <label>Adresa de email</label>
            <input
              type="email"
              name="toneli_email"
              value={formInputs.email}
              onChange={handleFormInputChange}
              autoComplete="off"
              required
            />
          </div>
          <div className="form-input-container">
            <label>Număr de telefon</label>
            <input
              type="text"
              name="toneli_telefon"
              value={formInputs.telefon}
              onChange={handleFormInputChange}
              autoComplete="off"
              inputMode="tel"
              required
            />
          </div>
          <div className="form-input-container">
            <label>Județ</label>
            <SelectInput
              data={judete}
              value={formInputs.judet}
              name={"toneli_judet"}
              onChange={handleCountyInputChange}
              isReadOnly={false}
            />
          </div>
          <div className="form-input-container">
            <label>Oraș</label>
            <SelectInput
              data={formInputs.judet !== "" ? orase[formInputs.judet] : []}
              value={formInputs.oras}
              name={"toneli_oras"}
              onChange={handleCityInputChange}
              isReadOnly={formInputs.judet === ""}
            />
          </div>
          <div className="form-checkbox-container">
            <label>
              <input
                type="checkbox"
                name="toneli_tc"
                checked={formInputs.tc}
                onChange={handleFormCheckboxChange}
                required
              />
              <span>Am citit și sunt de acord cu regulamentul campaniei</span>
            </label>
          </div>
          <div className="form-checkbox-container">
            <label>
              <input
                type="checkbox"
                name="toneli_gdpr"
                checked={formInputs.gdpr}
                onChange={handleFormCheckboxChange}
                required
              />
              <span>Am citit și sunt de acord cu reglementările GDPR</span>
            </label>
          </div>
          <div className="form-submit-container">
            <button disabled={loading}>Trimite</button>
          </div>
        </div>
      </form>
      {formError !== null ? (
        <>
          <div className="form-error-container">
            <div className="form-error-message">
              <div className="form-error-button">
                <button onClick={closeErrorMessage}>x</button>
              </div>
              <div className="form-error-content">
                <h2>Eroare</h2>
                <p>
                  {formError === "no-prizes" ? (
                    "Nu mai sunt premii disponibile pentru locația dvs."
                  ) : (
                    <>
                      Vă rugăm să completați{" "}
                      {formError === "nume" ? (
                        "numele"
                      ) : (
                        <>
                          {formError === "prenume" ? (
                            "prenumele"
                          ) : (
                            <>
                              {formError === "email" ? (
                                "adresa de email"
                              ) : (
                                <>
                                  {formError === "telefon" ? (
                                    "numărul de telefon"
                                  ) : (
                                    <>
                                      {formError === "judet" ? (
                                        "județul"
                                      ) : (
                                        <>
                                          {formError === "oras" ? (
                                            "orașul"
                                          ) : (
                                            <>
                                              {formError === "tc" ? (
                                                "acordul regulamentului"
                                              ) : (
                                                <>
                                                  {formError === "gdpr" ? (
                                                    "acordul GDPR"
                                                  ) : (
                                                    <>{}</>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default RegisterForm;
