import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const [error, setError] = useState("");
  const [formInputs, setFormInputs] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const jwt_token = localStorage.getItem("jwt_token");

    if (jwt_token) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);

  const handleFormInputChange = (e) => {
    const { value, name } = e.target;

    setFormInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const formData = new FormData();
    formData.append("username", formInputs.username);
    formData.append("password", formInputs.password);

    const response = await fetch("/api/v1/backend/login", {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      const body = await response.json();

      console.log(body);
      if (body.status === "success") {
        localStorage.setItem("jwt_token", body.user_id);

        navigate("/admin/dashboard");
      } else {
        setError(body.message);
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-form-container">
        <h2>Admin</h2>
        <form onSubmit={handleSubmit}>
          <div className="login-form-content">
            <div className="login-input-container">
              <label>Nume de utilizator</label>
              <input
                name="username"
                type="text"
                value={formInputs.username}
                onChange={handleFormInputChange}
                required
              />
            </div>
            <div className="login-input-container">
              <label>Parola</label>
              <input
                name="password"
                value={formInputs.password}
                type="password"
                onChange={handleFormInputChange}
                required
              />
            </div>
            <div className="login-submit-container">
              {error !== "" && (
                <p className="login-error-message">
                  {error === "Invalid credentials"
                    ? "Numele de utilizator sau parola sunt incorecte"
                    : "Completează datele de autentificare"}
                </p>
              )}
              <button>Autentificare</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
