import React, { useEffect, useRef, useState } from "react";
import scratchCardPath from "./scratch_card.png";

import { FaRegFaceFrown } from "react-icons/fa6";

function ScratCardGame({
  prize,
  changeToThirdPage,
  umbrelaImg,
  coolerboxImg,
  evantaiImg,
  newRegister,
}) {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [clearedPixels, setClearedPixels] = useState(0);
  const pixelThreshold = 200;

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const ctx = canvas.getContext("2d");

    const resizeCanvas = () => {
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      // Calculăm dimensiunile canvasului pentru a menține raportul de aspect 1000x800
      const aspectRatio = 1000 / 800;
      if (containerWidth / containerHeight > aspectRatio) {
        canvas.width = containerHeight * aspectRatio;
        canvas.height = containerHeight;
      } else {
        canvas.width = containerWidth;
        canvas.height = containerWidth / aspectRatio;
      }

      // Redesenăm imaginea pentru a se potrivi cu dimensiunea canvasului
      const image = new Image();
      image.src = scratchCardPath;
      image.onload = () => {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        setImageLoaded(true);
      };
    };

    // Setăm canvasul la dimensiunea inițială
    resizeCanvas();

    // Ascultăm schimbările de dimensiune ale containerului
    const resizeObserver = new ResizeObserver(resizeCanvas);
    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const startDrawing = (e) => {
    // e.preventDefault();
    setIsDrawing(true);
    draw(e);
  };

  const endDrawing = (e) => {
    e.preventDefault();
    setIsDrawing(false);
    checkScratchCompletion();
  };

  const draw = async (e) => {
    if (!isDrawing) return;

    // e.preventDefault();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const rect = canvas.getBoundingClientRect();
    const x = (e.clientX || e.touches[0].clientX) - rect.left;
    const y = (e.clientY || e.touches[0].clientY) - rect.top;

    ctx.globalCompositeOperation = "destination-out";
    ctx.lineWidth = 100;
    ctx.lineCap = "round";

    ctx.beginPath();
    ctx.arc(x, y, ctx.lineWidth / 2, 0, Math.PI * 2);
    ctx.fill();
    const imageData = ctx.getImageData(x, y, ctx.lineWidth, ctx.lineWidth);
    const pixels = imageData.data;
    let newClearedPixels = 0;

    for (let i = 3; i < pixels.length; i += 4) {
      if (pixels[i] === 0) newClearedPixels++;
    }

    setClearedPixels((prev) => prev + newClearedPixels);

    // Verifică progresul doar când se depășește pragul de pixeli
    if (clearedPixels + newClearedPixels >= pixelThreshold) {
      setClearedPixels(0); // Resetează numărătoarea
      checkScratchCompletion();
    }
  };

  const checkScratchCompletion = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;
    let totalCleared = 0;

    for (let i = 3; i < pixels.length; i += 4) {
      if (pixels[i] === 0) totalCleared++;
    }

    const totalPixels = canvas.width * canvas.height;
    const clearThreshold = totalPixels * 0.3;

    if (totalCleared >= clearThreshold) {
      canvas.style.display = "none"; // Ascunde canvasul dacă s-a zgâriat suficient
    }
  };

  const handleSignPV = (e) => {
    e.preventDefault();
    changeToThirdPage();
  };

  const handleNewRegister = (e) => {
    e.preventDefault();
    newRegister();
  };

  return (
    <div className="scratch-card-game" ref={containerRef}>
      {imageLoaded ? (
        <>
          <div className="scratch-card-prize">
            <div className="scratch-card-prize-content">
              {prize === "loser" ? (
                <FaRegFaceFrown className="loser-icon" />
              ) : (
                <img
                  src={
                    prize === "umbrela"
                      ? umbrelaImg
                      : prize === "cooler-bag"
                      ? coolerboxImg
                      : evantaiImg
                  }
                  className="scratch-card-prize-img"
                />
              )}

              {prize === "loser" ? (
                <>
                  <h3>Acum nu ai avut noroc...</h3>
                  <p>Dar nu fi supărat</p>
                  <p>Ai intrat în tombola pentru marele premiu!</p>

                  <button
                    className="sign-pv-button"
                    onClick={handleNewRegister}
                  >
                    Înscriere nouă
                  </button>
                </>
              ) : (
                <>
                  <h3>Felicitări!</h3>
                  <p>
                    Ați câștigat{" "}
                    {prize === "umbrela"
                      ? "o umbrelă de plajă"
                      : prize === "cooler-bag"
                      ? "un cooler bag"
                      : "un evantai"}
                  </p>
                  <p>Vă rugăm să semnați procesul verbal de predare-primire</p>
                  <button className="sign-pv-button" onClick={handleSignPV}>
                    Semnează procesul verbal
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div></div>
        </>
      )}
      <canvas
        ref={canvasRef}
        className="scratch-card-canvas"
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={endDrawing}
        onMouseLeave={endDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={endDrawing}
      />
    </div>
  );
}

export default ScratCardGame;
